.react-select-container {
  background: $input-bg !important;
  color: inherit !important;
}
.react-select__control {
  background: transparent !important;
  color: inherit !important;
  z-index: 10 !important;
  border-radius: $input-border-radius !important;
  border: 1px solid $input-border-color !important;
}
.react-select__value-container{
  color: inherit !important;
  z-index: 10 !important;
  & > div {
    color: inherit !important;
  }
}
.react-select__indicators{
  color: inherit !important;
  z-index: 10 !important;
}
.react-select__menu{
  color: inherit !important;
  background: inherit !important;
  z-index: 10 !important;
}
.react-select__menu-list{
  color: inherit !important;
  z-index: 10 !important;
  :hover {
    background-color: $tomato !important;
    color: $black !important;
  }
}
.react-select__option{
  color: inherit !important;
  z-index: 10 !important;
}
